<template>
  <validation-observer
    ref="refFormObserver"
    #default="{ invalid, pristine }"
  >
    <b-form @submit.prevent="submitUpdateEmployeeConfig">
      <!-- SECTION airport config  -->
      <b-card body-class="p-1">
        <h4>{{ $t('myAccount.employeeConfig.titleAirport') }}</h4>
        <b-row>
          <!-- ANCHOR departure code -->
          <b-col md="6">
            <b-form-group class="mb-0">
              <label
                class="h5"
                for="departure-code"
              >
                {{ $t('myAccount.employeeConfig.departureCode') }}
              </label>
              <validation-provider
                :name="$t('myAccount.employeeConfig.departureCode')"
                vid="departure-code"
                rules=""
              >
                <div class="flex-grow-1 border rounded p-25">
                  <SearchAirportSelect :data-prop.sync="newEmployeeConfig.departureCode" />
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- ANCHOR arrival code -->
          <b-col md="6">
            <b-form-group class="mb-0">
              <label
                class="h5"
                for="arrival-code"
              >
                {{ $t('myAccount.employeeConfig.arrivalCode') }}
              </label>
              <validation-provider
                :name="$t('myAccount.employeeConfig.arrivalCode')"
                vid="arrival-code"
                rules=""
              >
                <div class="flex-grow-1 border rounded p-25">
                  <SearchAirportSelect :data-prop.sync="newEmployeeConfig.arrivalCode" />
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <!-- !SECTION -->

      <!-- SECTION show config -->
      <b-card body-class="p-1">
        <h4>{{ $t('myAccount.employeeConfig.titleResultSearch') }}</h4>
        <b-row>
          <!-- ANCHOR Sắp xếp theo -->
          <b-col
            md="6"
            class="mb-1"
          >
            <h5>Sắp xếp theo</h5>
            <v-select
              v-model="newEmployeeConfig.sortType"
              :reduce="val => val.value"
              label="label"
              :options="sortItinerariesTypeOptions"
              :clearable="false"
              style="max-width: 240px;"
            >
              <template #option="data">
                <small class="font-weight-bolder text-truncate">{{ $te(`flight.viewConfig.${data.label}`) ? $t(`flight.viewConfig.${data.label}`) : data.label }}</small>
              </template>
              <template #selected-option="data">
                <small class="font-weight-bolder text-truncate">{{ $te(`flight.viewConfig.${data.label}`) ? $t(`flight.viewConfig.${data.label}`) : data.label }}</small>
              </template>
            </v-select>
          </b-col>

          <!-- ANCHOR Hiển thị giá -->
          <b-col
            md="6"
            class="mb-1"
          >
            <h5>Hiển thị giá</h5>
            <v-select
              v-model="newEmployeeConfig.showPriceOptions"
              :reduce="val => val.value"
              label="label"
              :options="showPriceOptions"
              :clearable="false"
              style="max-width: 240px;"
            >
              <template #option="data">
                <small class="font-weight-bolder text-truncate">{{ $te(`flight.viewConfig.${data.label}`) ? $t(`flight.viewConfig.${data.label}`) : data.label }}</small>
              </template>
              <template #selected-option="data">
                <small class="font-weight-bolder text-truncate">{{ $te(`flight.viewConfig.${data.label}`) ? $t(`flight.viewConfig.${data.label}`) : data.label }}</small>
              </template>
            </v-select>
          </b-col>

          <!-- ANCHOR isShowPromotion Hiển thị chiết khấu -->
          <b-col
            v-if="!isRoleF1"
            md="6"
          >
            <validation-provider
              :name="$t('myAccount.employeeConfig.isShowPromotion')"
              vid="isShowPromotion"
              rules=""
            >
              <label class="d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content">
                <BFormCheckbox
                  v-model="newEmployeeConfig.isShowPromotion"
                  switch
                  class="custom-control-warning"
                />
                <h5 class="mb-0">
                  {{ $t('myAccount.employeeConfig.isShowPromotion') }}
                </h5>
              </label>
            </validation-provider>
          </b-col>

          <!-- ANCHOR isShowFee Hiển thị phí xuất vé -->
          <b-col
            v-if="!getHideFee && !isRoleF1"
            md="6"
          >
            <validation-provider
              :name="$t('myAccount.employeeConfig.isShowFee')"
              vid="isShowFee"
              rules=""
            >
              <label class="d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content">
                <BFormCheckbox
                  v-model="newEmployeeConfig.isShowFee"
                  switch
                  class="custom-control-warning"
                />
                <h5 class="mb-0">
                  {{ $t('myAccount.employeeConfig.isShowFee') }}
                </h5>
              </label>
            </validation-provider>
          </b-col>

          <!-- ANCHOR isShowFeeService Hiển thị phí dịch vụ -->
          <b-col md="6">
            <validation-provider
              :name="$t('myAccount.employeeConfig.isShowFeeService')"
              vid="isShowFeeService"
              rules=""
            >
              <label class="d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content">
                <BFormCheckbox
                  v-model="newEmployeeConfig.isShowFeeService"
                  switch
                  class="custom-control-warning"
                />
                <h5 class="mb-0">
                  {{ $t('myAccount.employeeConfig.isShowFeeService') }}
                </h5>
              </label>
            </validation-provider>
          </b-col>

          <!-- ANCHOR isShowBookingClass Hiển thị hạng vé -->
          <b-col md="6">
            <validation-provider
              :name="$t('myAccount.employeeConfig.isShowBookingClass')"
              vid="isShowBookingClass"
              rules=""
            >
              <label class="d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content">
                <BFormCheckbox
                  v-model="newEmployeeConfig.isShowBookingClass"
                  switch
                  class="custom-control-warning"
                />
                <h5 class="mb-0">
                  {{ $t('myAccount.employeeConfig.isShowBookingClass') }}
                </h5>
              </label>
            </validation-provider>
          </b-col>

          <!-- ANCHOR isShowAircraft Hiển thị loại máy bay -->
          <b-col md="6">
            <validation-provider
              :name="$t('myAccount.employeeConfig.isShowAircraft')"
              vid="isShowAircraft"
              rules=""
            >
              <label class="d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content">
                <BFormCheckbox
                  v-model="newEmployeeConfig.isShowAircraft"
                  switch
                  class="custom-control-warning"
                />
                <h5 class="mb-0">
                  {{ $t('myAccount.employeeConfig.isShowAircraft') }}
                </h5>
              </label>
            </validation-provider>
          </b-col>
        </b-row>
      </b-card>
      <!-- !SECTION -->

      <!-- SECTION feature config -->
      <b-card body-class="p-1">
        <h4>{{ $t('myAccount.employeeConfig.titleFeature') }}</h4>
        <b-row>
          <!-- ANCHOR isPriceBargainFinder Tính năng tính giá rẻ nhất (1S) -->
          <b-col
            v-if="!isRoleF1"
            md="6"
          >
            <validation-provider
              :name="$t('myAccount.employeeConfig.isPriceBargainFinder')"
              vid="isPriceBargainFinder"
              rules=""
            >
              <label class="d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content">
                <BFormCheckbox
                  v-model="newEmployeeConfig.isPriceBargainFinder"
                  switch
                  class="custom-control-warning"
                  @click.native.prevent="updateIsPriceBargainFinder"
                />
                <h5 class="mb-0">
                  {{ $t('myAccount.employeeConfig.isPriceBargainFinder') }}
                </h5>
              </label>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              :name="$t('myAccount.employeeConfig.showAddonForSms')"
              vid="showAddonForSms"
              rules=""
            >
              <label class="d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content">
                <BFormCheckbox
                  v-model="newEmployeeConfig.showAddonForSms"
                  switch
                  class="custom-control-warning"
                />
                <h5 class="mb-0">
                  {{ $t('myAccount.employeeConfig.showAddonForSms') }}
                </h5>
              </label>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              :name="$t('myAccount.employeeConfig.showArrivalTimeForSms')"
              vid="showArrivalTimeForSms"
              rules=""
            >
              <label class="d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content">
                <BFormCheckbox
                  v-model="newEmployeeConfig.showArrivalTimeForSms"
                  switch
                  class="custom-control-warning"
                />
                <h5 class="mb-0">
                  {{ $t('myAccount.employeeConfig.showArrivalTimeForSms') }}
                </h5>
              </label>
            </validation-provider>
          </b-col>
        </b-row>
      </b-card>
      <!-- !SECTION -->

      <!-- ANCHOR buttons -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex-center gap-3"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :disabled="invalid"
            type="submit"
          >
            {{ $t('saveChanges') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            :disabled="pristine"
            @click="resetNewEmployeeConfig"
          >
            {{ $t('reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import VSelect from 'vue-select'

import { sortItinerariesTypeOptions, showPriceOptions } from '@/constants/selectOptions'
import store from '@/store'
import { apiEmployee } from '@/api'

import storeModule from '@flightv2/bookingStoreModule'

import useToast from '@useToast'
import { required, confirmed } from '@validations'

export default {
  components: {
    VSelect,
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    SearchAirportSelect: () => import('@flightv2/search/components/SearchAirportSelect.vue'),
  },
  directives: {
    Ripple,
  },
  setup() {
    const { toastSuccess, toastError } = useToast()

    if (!store.hasModule('app-flight-v2')) {
      store.registerModule('app-flight-v2', storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule('app-flight-v2')) {
        store.unregisterModule('app-flight-v2')
      }
    })
    store.dispatch('app-flight-v2/fetchAirportGroup')

    const employeeConfig = computed(() => store.getters['userStore/getEmployeeConfig'])
    const employeeId = computed(() => store.getters['userStore/getMeDataId'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const blankEmployeeConfig = ref(
      {
        sortType: 'CHEAPEST_FARE',
        showPriceOptions: 'TOTAL_FARE',
        arrivalCode: 'SGN',
        departureCode: 'HAN',
        isShowPromotion: true,
        isShowFee: false,
        isShowFeeService: true,
        isShowBookingClass: true,
        isShowAircraft: true,
        isPriceBargainFinder: false,
        showAddonForSms: false,
        showArrivalTimeForSms: true,
      },
    )

    const newEmployeeConfig = ref(cloneDeep(blankEmployeeConfig.value))
    const resetNewEmployeeConfig = () => {
      newEmployeeConfig.value = cloneDeep(employeeConfig.value || blankEmployeeConfig.value)
    }

    watch(employeeConfig, () => {
      resetNewEmployeeConfig()
    }, { deep: true, immediate: true })

    function submitUpdateEmployeeConfig() {
      const arrivalCode = newEmployeeConfig.value.arrivalCode.iata ?? newEmployeeConfig.value.arrivalCode
      const departureCode = newEmployeeConfig.value.departureCode.iata ?? newEmployeeConfig.value.departureCode
      if (arrivalCode === departureCode) {
        toastError({
          title: 'messagesList.error',
          content: 'myAccount.employeeConfig.errorUpdateConfigDuplicateCode',
        })
        return
      }
      store.dispatch('app/setLoading', true)
      const payload = {
        employeeConfig: {
          ...newEmployeeConfig.value,
          arrivalCode,
          departureCode,
        },
      }
      apiEmployee.updateEmployeeConfig(employeeId.value, payload)
        .then(() => {
          toastSuccess({
            title: 'messagesList.success',
            content: 'myAccount.employeeConfig.successUpdateConfig',
          })
        })
        .catch(error => {
          console.error({ error })
          toastError({
            title: 'messagesList.error',
            content: 'myAccount.employeeConfig.errorUpdateConfig',
          })
        })
        .finally(() => {
          store.dispatch('userStore/fetchMeData')
          store.dispatch('app/setLoading', false)
        })
    }
    const getHideFee = computed(() => store.getters['userStore/getHideFee'])

    function updateIsPriceBargainFinder() {
      if (!newEmployeeConfig.value.isPriceBargainFinder) {
        const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: 'Anh/Chị vui lòng nắm rõ nghiệp vụ trước khi bật tính năng này, chúng tôi miễn trừ trách nhiệm trong quá trình sử dụng.<br/>Vui lòng liên hệ Team Sales/Booker để hỗ trợ trước khi bật lại tính năng.' } })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'md',
            okVariant: 'info',
            okTitle: this.$t('modal.iamConfirm'),
            cancelTitle: this.$t('modal.close'),
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              newEmployeeConfig.value.isPriceBargainFinder = !newEmployeeConfig.value.isPriceBargainFinder
            }
          })
      } else {
        newEmployeeConfig.value.isPriceBargainFinder = !newEmployeeConfig.value.isPriceBargainFinder
      }
    }

    return {
      required,
      confirmed,
      newEmployeeConfig,
      isRoleF1,
      resetNewEmployeeConfig,
      submitUpdateEmployeeConfig,
      sortItinerariesTypeOptions,
      showPriceOptions,
      getHideFee,
      updateIsPriceBargainFinder,
    }
  },
}
</script>
